<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Month</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Month"
                rules="required"
              >
                <v-select
                  :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="month"
                  placeholder="None"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Year</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Year"
                rules="required"
              >
                <v-select
                  :options="[
                    1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
                    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
                    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
                    2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039,
                    2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049,
                    2050, 2051, 2052,
                  ]"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="year"
                  placeholder="None"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Bank Name"
                rules="required"
              >
                <v-select
                  v-model="bankname"
                  :options="bankOptions"
                  label="bankname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label
              ><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Remarks"
                rules="required"
              >
                <b-form-input v-model="remarks" placeholder="Enter Remarks" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment</label>
              <b-form-file
                v-model="documents.attachment1"
                placeholder="Select Attachment"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="
                  handleFileChange($event, 'attachment1', 'bankstatement')
                "
              />
            </b-form-group>
            <attachment :data="documents.attachment1" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment</label>
              <b-form-file
                v-model="documents.attachment2"
                placeholder="Select Attachment"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="
                  handleFileChange($event, 'attachment2', 'bankstatement')
                "
              />
            </b-form-group>
            <attachment :data="documents.attachment2" />
          </b-col>
          <b-col md="12">
            <b-button
              class="mt-1"
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              :disabled="disabled"
            >
              Submit
            </b-button>
            <b-button
              class="mt-1 ml-2"
              variant="primary"
              type="submit"
              @click="handleBack"
              :disabled="flag"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import Attachment from "../../../../components/Attechment.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
    Attachment,
  },
  data() {
    return {
      month: "",
      year: "",
      bankname: "",
      remarks: "",
      documents: {
        attachment1: "",
        attachment2: "",
      },
      disabled: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      bankOptions: [],
      flag: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Bankstatement") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/account/bankstatement");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/account/bankstatement");
          }
        }
      });
    }
    this.getBank();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      axios({
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getbankstatementById/${this.$route.params.id}`,
      }).then((response) => {
        this.getEditItems(response.data.data);
      });
    },
    getEditItems(item) {
      item.map((item) => {
        this.month = item.month;
        this.year = item.year;
        this.bankname = this.getBank(item.bankname);
        this.remarks = item.remarks;
        this.documents.attachment1 = item.attachment1;
        this.documents.attachment2 = item.attachment2;
      });
    },
    submitForm(e) {
      const obj = {
        month: this.month,
        year: this.year,
        bankname: this.bankname.id,
        remarks: this.remarks,
        attachment1: this.documents.attachment1,
        attachment2: this.documents.attachment2,
      };
      const ifEdit = !!this.$route.params.id;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        const accessToken = localStorage.getItem("accessToken");
        const baseApi = process.env.VUE_APP_APIENDPOINT;
        if (success) {
          this.flag = true;

          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/bankstatement/${this.$route.params.id}`
              : `${baseApi}/bankstatement`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(obj),
          })
            .then((json) => {
              this.flag = false;

              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/master/accounting/bankstatement");
            })
            .catch((error) => {
              this.flag = false;
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleBack() {
      this.$router.push("/master/accounting/bankstatement");
    },
    async getBank(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bank`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankdata = response.data.data.data;
          this.bankOptions = [];
          this.bankdata.map((item) => {
            if (item.bankname !== null) {
              this.bankOptions.push(item);
            }
            if (item.id == id) {
              this.bankname = item.bankname;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleFileChange(e, name, type) {
      this.disabled = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.disabled = false;
        })
        .catch((error) => {
          this.disabled = true;
          console.log(error, "error");
        });
    },
  },
};
</script>
